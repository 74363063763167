<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import keywordsTracking from "@/api/modules/keywords-tracking";

const props = defineProps({
  keyword: {
    type: String,
    required: true,
  },
  store: {
    type: Object,
    required: true,
  },
  country: {
    type: Object,
    required: true,
  },
  rankInfo: {
    type: Object,
    required: true,
  },
})

const lastCheckedAt = computed(() => {
  return props.rankInfo.last_check_date_time ? new Date(props.rankInfo.last_check_date_time) : null
})
const lastSentToCheckAt = computed(() => {
  return props.rankInfo.sent_to_warm_at ? new Date(props.rankInfo.sent_to_warm_at) : null
})

const now = ref(null)

const updateNow = () => {
  now.value = new Date(new Date().toLocaleString('en-US', {timeZone: 'UTC'}));
}
updateNow()

const interval = setInterval(updateNow, 1000)
onUnmounted(() => {
  clearInterval(interval);
})

const getDiffInSeconds = (date) => {
  return Math.floor((now.value - date) / 1000);
}

const state = computed(() => {
  return keywordsTracking.getAllowToRecheckState(lastCheckedAt.value, lastSentToCheckAt.value);
})

const formattedTimeFromCheck = computed(() => {
  if (!lastCheckedAt.value) {
    return 'N/A';
  }

  const diff = getDiffInSeconds(lastCheckedAt.value);
  if (diff < 60) {
    return `${diff} sec ago`;
  }

  const hours = Math.floor(diff / 3600);
  const minutes = Math.floor((diff - hours * 3600) / 60);
  return `${hours} hours ${minutes} min ago`;
})

const requestUpdate = () => {
  //TODO сомнительно но ок (формат меняется. лучше уже с бека брать)
  props.rankInfo.sent_to_warm_at = new Date().toLocaleString('en-US', {timeZone: 'UTC'});

  keywordsTracking.forceRecheckKeywordsRank(
      props.store.key,
      [props.keyword],
      props.country.code,
  )
}
</script>

<template>
<div class="ReloadRankPositionTooltip">
  <div class="lastChecked">Last checked: <span>{{formattedTimeFromCheck}}</span></div>
  <div v-show="state === 'updating'" class="updating">
    <ReloadIcon color="#FCB852"/> Updating...
  </div>
  <div v-show="state === 'allowed_to_update'" class="request" @click="requestUpdate">
    <ReloadIcon/> Request updating
  </div>
</div>
</template>

<style scoped lang="scss">
.ReloadRankPositionTooltip {
  .lastChecked {
    color: var(--Neutral-700, #838E9E);

    span {
      color: #505660;
      font-weight: 600;
    }
  }

  .updating, .request {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
  }

  .request {
    cursor: pointer;
    color: var(--Primary-Text, #414AEC);
    text-decoration: underline;
  }

  .updating {
    color: var(--secondary-orange, #FCB852);
  }
}
</style>