<template>
  <div class="app-details-component">
    <div class="tab-component-title screenshots">Screenshots</div>
    <modal-app-screenshots :screenshots-data="screenshotsComputed"
                           :is-screenshots-data-loaded="screenshotsDataLoaded"
                           :top-locales-data="preparedScreenshotsLocales"
                           :current-prop-locale="countryMainLocale"
                           :current-competitor-store="appData.store">
      <template v-slot:upper-options>
        <div class="upper-options">
          <div class="device-list">
            <template v-if="appData.store === 'APP_STORE'">
              <div v-if="isExistDeviceScreenshots('iphone')" class="device-item"
                   @click="isExistDeviceScreenshots('ipad') ? changeDevice('iphone') : null"
                   :class="{active: currentDevice === 'iphone'}">iPhone
              </div>
              <div v-if="isExistDeviceScreenshots('ipad')" class="device-item"
                   @click="isExistDeviceScreenshots('iphone') ? changeDevice('ipad') : null"
                   :class="{active: currentDevice === 'ipad'}">iPad
              </div>
            </template>
          </div>
          <div class="localization-dropdown">
            <div class="localization-title">Localization:</div>
            <custom-dropdown v-if="preparedScreenshotsLocales?.length > 0"
                             :close-dropdown="closeLocalesList">
              <template v-slot:header>
                {{ countryMainLocale.title }}
              </template>
              <template v-slot:content>
                <div class="content-items-list">
                  <div class="dropdown-item"
                       @click="localeItemClicked(item)"
                       v-for="item in preparedScreenshotsLocales">
                    {{ item.value }}
                    <template v-if="item.countryValue">
                      ({{ item.countryValue }})
                    </template>
                  </div>
                </div>
              </template>
            </custom-dropdown>
          </div>
        </div>
      </template>

      <template v-slot:loader>
        <div class="preloader-wrap">
          <preloader-table-small loader-size="50px"></preloader-table-small>
        </div>
      </template>
    </modal-app-screenshots>
  </div>
</template>

<script>
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import ModalAppScreenshots from "@/views/Analyze/AnalyzeApp/components/ModalAppScreenshots/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";

export default {
  name: "AppScreenshots" ,
  components: {PreloaderTableSmall, ModalAppScreenshots, CustomDropdown},
  props: {
    appData: {
      type: Object,
    },
    filterCountriesArray: {
      type: Array
    },
    filterLocalesArray: {
      type: Array
    }
  },
  data() {
    return {
      currentDevice: 'iphone',
      screenshotsDataLoaded: false,
      closeLocalesList: 0,
      countryMainLocale: {},
      appScreenshotsData: {},
      screenshotsLocales: [],
    }
  },
  mounted() {
    this.fetchFilledLocales();
  },
  methods: {
    changeDevice(device) {
      this.currentDevice = device;
      this.screenshotsDataLoaded = false;

      setTimeout(() => {
        this.screenshotsDataLoaded = true;
      }, 300);
    },
    localeItemClicked(item) {
      this.closeLocalesList++;
      this.countryMainLocale = {...item, title: item.value};
    },
    setCurrentCountryLocale() {
      const currentCountryMainLocale = this.filterCountriesArray.find(country => country?.code === this.currentCountry?.code);

      if (currentCountryMainLocale?.locales?.main) {
        const localeName = this.filterLocalesArray.find(locale => locale.code === currentCountryMainLocale?.locales?.main);
        this.countryMainLocale = {
          code: currentCountryMainLocale?.locales?.main,
          title: localeName?.name,
        };
      } else {
        const localeName = this.filterLocalesArray.find(locale => locale.code === 'en');
        this.countryMainLocale = {
          code: localeName?.code,
          title: localeName?.name,
        };
      }
    },
    async fetchAppScreenshotsData() {
      this.screenshotsDataLoaded = false;
      this.appScreenshotsData = [];

      const appScreenshotsData = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.APP_SCREENSHOTS
          + '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData.id + '&locale_code=' + this.countryMainLocale.code
      );

      if (JSON.stringify(appScreenshotsData) !== '{}' && appScreenshotsData?.list?.length > 0) {
        this.appScreenshotsData = {...appScreenshotsData};
      }

      this.screenshotsDataLoaded = true;
    },
    async fetchFilledLocales() {
      this.screenshotsLocales  = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.APP_FILLED_LOCALES + '/' + this.appData.id
      );
    },
    decodeLocaleItem(fullLocalesArray, code) {
      let localeString = '';
      const localeFullData = fullLocalesArray.find(item => item?.code?.toLowerCase() === code?.toLowerCase());
      if (localeFullData) {
        return localeString = localeFullData?.name;
      } else {
        return code;
      }
    },
    isExistDeviceScreenshots(device) {
      if (!this.appScreenshotsData.list_by_device) {
        return false;
      }

      const keysToLower = Object.keys(this.appScreenshotsData.list_by_device).map(key => key.toLowerCase());
      const deviceKeys = keysToLower.filter(key => key.startsWith(device))

      return deviceKeys.length;
    }
  },
  computed: {
    ...mapGetters([
      'currentCountry',
      'currentCountryManual',
    ]),
    screenshotsComputed() {
      const priorityByDevice = {
        IPHONE: ['iphone_d74', 'iphone_d73', 'iphone_6_5', 'iphone_5_8', 'iphone6+', 'iphone6', 'iphone5', 'iphone'],
        IPAD: ['ipadPro_2018', 'ipad_11', 'ipad', 'ipad_10_5', 'ipadPro'],
      };

      if (this.appData?.store !== 'APP_STORE') {
        return this.appScreenshotsData?.list;
      }

      const lowerCurrentDevice = this.currentDevice.toLowerCase();

      let deviceType = Object.keys(priorityByDevice).find((type) => {
        return priorityByDevice[type].some((device) =>
            device.toLowerCase().startsWith(lowerCurrentDevice)
        );
      });

      if (!deviceType) {
        return [];
      }

      const key = priorityByDevice[deviceType].find((device) => {
        return Object.keys(this.appScreenshotsData?.list_by_device || {}).some((listDevice) =>
            listDevice.toLowerCase().startsWith(device.toLowerCase())
        );
      });

      let result = [];

      if (key) {
        result = this.appScreenshotsData.list_by_device[key];

        if (!result) {
          result = this.appScreenshotsData.list_by_device[key.toUpperCase()];
        }
      }

      return result ?? [];
    },
    preparedScreenshotsLocales() {
      let preparedLocales = [];
      this.screenshotsLocales.forEach(code => {
        let splittedCode = code.split('_');

        preparedLocales.push({
          code: code,
          value: this.decodeLocaleItem(this.filterLocalesArray, code),
          countryValue: splittedCode?.length > 1 ? this.decodeLocaleItem(this.filterCountriesArray, splittedCode[1]) : null,
        });
      });

      return preparedLocales;
    },
  },
  watch: {
    async countryMainLocale() {
      await this.fetchAppScreenshotsData();
    },
    filterCountriesArray() {
      if (this.filterCountriesArray.length > 0) {
        this.setCurrentCountryLocale();
      }
    },
  }
}
</script>
<style lang="scss" src="./style.scss" scoped></style>