<template>
  <div class="app-details-component">

    <modal-app-upper-details :data="modalData.topAppData"
                             :competitor-id="appData?.id"
                             :is-loaded="appUpperDetailsLoaded"
                             :can-select-competitors="canSelectCompetitors"
                             @competitor-changed="modalCompetitorChanged"
                             :competitor-type="currentCompetitorType"
                             @live-app-clicked="liveAppClickHandler"/>

    <div class="app-details-tabs-content">
      <template v-if="isTablet || isMobile">
        <div class="header-tabs-links">
          <div class="mobile-tabs-content">
            <div class="mobile-tabs-heading">
              <div v-for="key in Object.keys(this.tabs)"
                   class="tabs-heading-item"
                   :class="{active: setActiveClass(key)}"
                   @click="switchCurrentTab(key)">
                {{ tabs[key] }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="tabs-heading">
          <div v-for="key in Object.keys(this.tabs)"
               class="tabs-heading-item"
               :class="{active: setActiveClass(key)}"
               @click="switchCurrentTab(key)">
            {{ tabs[key] }}
          </div>
        </div>
      </template>

      <div class="tabs-content"
           v-if="!loading">
        <keep-alive>
          <component :is="currentComponent"
                     :filter-countries-array="modalData.filterCountriesArray"
                     :filter-locales-array="modalData.filterLocalesArray"
                     :app-data="{...appData, ...modalData.topAppData.app}"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import ModalAppUpperDetails from "@/views/Analyze/AnalyzeApp/components/ModalAppUpperDetails";
import ModalAppScreenshots from "@/views/Analyze/AnalyzeApp/components/ModalAppScreenshots";
import ModalAppMetricsNew from "@/views/Analyze/AnalyzeApp/components/ModalAppMetrics";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import ModalAppMetrics from "@/views/CompetitorsFinderPage/components/ModalAppMetrics";
import {httpRequest} from "@/api";
import {shortenDigits, getCookie} from "@/utils/utils";
import {mapGetters} from "vuex";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown";
import {UserAppsArrayCreator} from "@/utils/factories";
import InstallsAndRevenue from "@/components/AppDetailsComponent/components/InstallsAndRevenue/index.vue";
import Overview from "@/components/AppDetailsComponent/components/Overview/index.vue";
import CategoryRanking from "@/components/AppDetailsComponent/components/CategoryRanking/index.vue";
import TextMetadata from "@/components/AppDetailsComponent/components/TextMetadata/index.vue";
import MetricsData from "@/components/AppDetailsComponent/components/MetricsData/index.vue";
import ModalAppBottomDetails from "@/views/CompetitorsFinderPage/components/ModalAppBottomDetails/index.vue";
import user from "@/api/modules/user";
import competitorState from "@/api/modules/competitor-state";
import SearchVisibility from "@/components/AppDetailsComponent/components/SearchVisibility/index.vue";

export default {
  name: "AppDetailsComponent",
  components: {
    MetricsData,
    TextMetadata,
    SvgIcon,
    'modal-app-upper-details': ModalAppUpperDetails,
    'modal-app-metrics-new': ModalAppMetricsNew,
    'modal-app-screenshots': ModalAppScreenshots,
    'preloader-table-small': PreloaderTableSmall,
    'modal-app-metrics': ModalAppMetrics,
    'modal-app-bottom-details': ModalAppBottomDetails,
    CustomDropdown,
    'installs': InstallsAndRevenue,
    'search-visibility': SearchVisibility,
    'category-ranking': CategoryRanking,
    'overview': Overview,
  },
  props: {
    opened: {
      type: Boolean,
    },
    appData: {
      type: Object,
    },
    changeCompetitorIn: {
      type: Boolean,
    },
    transparentBg: {
      type: Boolean,
      default: false
    },
    updateExploreComponent: {
      type: Number
    },
    canSelectCompetitors: {
      type: Boolean,
      default: true
    },
    useCountryMainLocale: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['modal-closed', 'competitor-changed'],
  data() {
    return {
      tabs: {
        'overview': 'Overview',
        'installs': 'Installs & Revenue',
        'search-visibility': 'Search Visibility',
        'category-ranking': 'Category Ranking',
        //'rating': 'Rating',
      },
      clickedItem: {},
      loaderText: '',
      resultsLoading: false,
      competitorsTabsLoading: false,
      showAppDetailsModal: false,
      competitorsArray: [],
      currentPage: 1,
      modalData: {
        topAppData: {},
        appInfoData: {},
        appVersionHistoryData: {},
        appMetricsData: {},
        appMetaData: [],
        appRankingKeywordsData: [],
        appCategoryRankingData: [],
        filterLocalesArray: [],
        filterCountriesArray: [],
      },
      categoryRankingsLoaded: false,
      rankingKeywordsLoaded: false,
      metadataLoaded: false,
      appUpperDetailsLoaded: false,
      appInfoDataLoaded: false,
      historyDataLoaded: false,
      competitorsCount: 0,
      currentCompetitorType: '',
      currentCompetitorStore: '',
      userAppsList: [],
      closeLocalesList: 0,
      currentTab: 'overview',
      currentComponent: 'overview',
      tabDropdownOpened: false,
      loading: false,
    }
  },
  async mounted() {
    await this.prepareAllData();
    await this.fetchFilterLocales();
    const url = '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData?.id;
    await this.fetchAppMetricsData(url);
  },
  methods: {
    async modalCompetitorChanged(obj) {
      if (this.changeCompetitorIn) {
        const {type, id, competitorType} = obj;
        await this.competitorChanged(type, id, competitorType);
      }

      this.$emit('competitor-changed', obj);
    },
    async competitorChanged(type, id, competitorType = null) {
      await competitorState.competitorChanged(
        type,
        id,
        competitorType,
        this.currentCountry?.id,
      );
    },
    async competitorMarkedHandler(type, id) {
      let formData = new FormData();
      formData.append('app_id', getCookie('currentAppId'));
      formData.append('comp_app_id', this.appData?.id);
      formData.append('country_id', this.currentCountry?.id);
      formData.append('is_competitor', type);

      return await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.CUSTOM_COMPETITORS, formData);
    },
    async deleteAppCompetitorClickHandler(app_id) {
      const competitorsUrl = process.env.VUE_APP_API_URL
          + this.$ApiUrls.keywordsTracking.CUSTOM_COMPETITORS
          + '?app_id=' + getCookie('currentAppId')
          + '&comp_app_id=' + app_id
          + '&country_id=' + this.currentCountry?.id;

      return await httpRequest('DELETE', competitorsUrl);
    },
    markChangedCompetitors(id, type, mode = 'new') {
      const processedArray = [...this.competitorsArray];
      const index = processedArray?.findIndex(item => item?.id === id);
      if (index < 0) {
        return;
      }

      if (mode === 'new') {
        processedArray[index].competitorType = type;
      } else {
        processedArray[index].competitorType = null;
      }
      this.competitorsArray = [...processedArray];
    },
    setActiveCompetitors() {
      const competitorsFiltered = this.competitorsResults?.resultIsCompetitors?.filter(competitor => competitor === this.appData?.id);
      const indirectCompetitorsFiltered = this.competitorsResults?.resultIndirectCompetitors?.filter(competitor => competitor === this.appData?.id);
      const nonCompetitorsFiltered = this.competitorsResults?.resultNotCompetitors?.filter(competitor => competitor === this.appData?.id);

      if (competitorsFiltered?.length > 0) {
        this.currentCompetitorType = 'competitor';
      } else if (indirectCompetitorsFiltered?.length > 0) {
        this.currentCompetitorType = 'indirect';
      } else if (nonCompetitorsFiltered?.length > 0) {
        this.currentCompetitorType = 'nonCompetitor';
      }
    },
    //ExplorerModal components promises bundles
    async sendTopAppPromisesBundle(appId, url) {
      if (this.useCountryMainLocale) {
        url += '&use_country_main_locale=true';
      }

      this.appUpperDetailsLoaded = false;
      const promiseUrls = [
        {
          url: process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.TOP_APP_DATA + url,
          method: 'GET',
        },
      ];

      await this.$store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);
      const topAppData = this.promiseSettledData?.data[0]?.value;

      if (this.promiseSettledData.success) {
        if (JSON.stringify(topAppData) !== '{}') {
          let isAdded = false;

          for (const key in this.userAppsList) {
            if (topAppData?.app?.id === this.userAppsList[key]?.id) {
              isAdded = true;
            }
          }

          this.modalData.topAppData = {...topAppData, isAdded};
        }
      }

      this.appUpperDetailsLoaded = true;
    },
    async fetchCompetitorsCount(appId) {
      const currentAppId = appId ?? getCookie('currentAppId');
      const competitorsUrl = '?app_id=' + currentAppId;
      const competitorsData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.CUSTOM_COMPETITORS + competitorsUrl + '&is_competitor=0');

      if (competitorsData?.list?.isArray) {
        this.competitorsCount = competitorsData?.list?.length;
      } else {
        this.competitorsCount = Object.values(competitorsData?.list)?.length;
      }
    },
    async fetchFilterLocales() {
      const localesData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_LOCALES);
      const countriesData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_COUNTRIES);

      if (localesData?.length > 0 && countriesData?.length > 0) {
        user.LOCALES_FIX.forEach((locale) => {
          localesData.push(locale);
        });
        this.modalData.filterLocalesArray = [...localesData];
        this.modalData.filterCountriesArray = [...countriesData];
      }
    },
    async fetchAppMetricsData(url) {
      await this.sendTopAppPromisesBundle(this.appData?.id, url);
    },
    async setAppsList() {
      // const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.RECENTLY_USED_APPLICATIONS);
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW);
      this.userAppsList = UserAppsArrayCreator(result);
    },
    closeModalHandler(modalName) {
      this.$emit('modal-closed', modalName);
      this.closeModal(modalName);
    },
    async fetchCompetitorsBundle() {
      await competitorState.fetchCompetitors();
      this.setActiveCompetitors();
    },
    async liveAppClickHandler(app) {
      let formData = new FormData();
      formData.append('application_id', app.application_id);
      formData.append('country_code', this.currentCountry?.code);
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS, formData);
      this.$store.dispatch('UPDATE_USER_LIMITS');

      if (!result?.errors && result?.message !== 'limits exceeded') {
        let topAppData = {...this.modalData.topAppData};
        topAppData.isAdded = true;

        this.modalData.topAppData = {...topAppData};
      }
    },
    async prepareAllData() {
      this.categoryRankingsLoaded = false;
      this.rankingKeywordsLoaded = false;
      this.metadataLoaded = false;
      this.appUpperDetailsLoaded = false;
      this.appInfoDataLoaded = false;
      this.historyDataLoaded = false;
      this.showAppDetailsModal = true;
      await this.fetchCompetitorsBundle();
      this.clickedItem = {
        id: this.appData?.id ?? null,
        title: this.appData?.title ?? '',
        store: this.appData?.store ?? null,
      };

      this.currentCompetitorStore = this.appData?.store;

      this.modalData = {
        topAppData: {},
        appInfoData: {},
        appVersionHistoryData: {},
        appMetricsData: {},
        appMetaData: [],
        appRankingKeywordsData: [],
        appCategoryRankingData: [],
        filterLocalesArray: [],
        filterCountriesArray: [],
      };
    },
    switchCurrentTab(tabName) {
      this.currentTab = tabName;
      this.currentComponent = tabName;
      this.tabDropdownOpened = false;
    },
    setActiveClass(tabName) {
      return this.currentTab === tabName;
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'promiseSettledData',
      'pageMeta',
      'isMobile',
      'isTablet',
      'userSubscription',
    ]),
    ...mapGetters('keywordsTracking', [
      'sortingMeta',
      'competitorsResults',
    ]),
    hideInstalls() {
      return this.userSubscription?.plan_name === 'Free';
    },
  },
  watch: {
    async updateExploreComponent(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loading = true;
        await this.prepareAllData();
        this.loading = false;
        const url = '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData?.id;
        await this.fetchAppMetricsData(url);
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.code !== oldVal?.code) {
        await this.prepareAllData();
        const url = '?country_code=' + newVal?.code + '&app_id=' + this.appData?.id;
        await this.fetchAppMetricsData(url);
      }
    },
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id && oldVal?.id) {
        await this.prepareAllData();
        const url = '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData?.id;
        await this.fetchAppMetricsData(url);
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>